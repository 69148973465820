import React from 'react'
import { ScaleIcon, LightningBoltIcon, AcademicCapIcon, ShieldCheckIcon } from '@heroicons/react/outline'

export default function Reassurance() {
  return (
    <div className="bg-white mt-16">
      <div className="max-w-7xl mx-auto divide-y divide-gray-200 lg:py-8 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x">
        <div className="py-8 lg:py-0 lg:w-1/4 lg:flex-none">
          <div className="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8">
            <AcademicCapIcon className="h-10 w-10 text-blue-500" aria-hidden="true" />
            <div className="ml-4 flex-auto flex flex-col-reverse">
              <h3 className="font-medium text-gray-900">Compétences</h3>
            </div>
          </div>
        </div>
        <div className="py-8 lg:py-0 lg:w-1/4 lg:flex-none">
          <div className="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8">
            <ScaleIcon className="h-10 w-10 text-blue-500" aria-hidden="true" />
            <div className="ml-4 flex-auto flex flex-col-reverse">
              <h3 className="font-medium text-gray-900">Indépendance</h3>
            </div>
          </div>
        </div>
        <div className="py-8 lg:py-0 lg:w-1/4 lg:flex-none">
          <div className="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8">
            <LightningBoltIcon className="h-10 w-10 text-blue-500" aria-hidden="true" />
            <div className="ml-4 flex-auto flex flex-col-reverse">
              <h3 className="font-medium text-gray-900">Réactivité</h3>
            </div>
          </div>
        </div>
        <div className="py-8 lg:py-0 lg:w-1/4 lg:flex-none">
          <div className="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8">
            <ShieldCheckIcon className="h-10 w-10 text-blue-500" aria-hidden="true" />
            <div className="ml-4 flex-auto flex flex-col-reverse">
              <h3 className="font-medium text-gray-900">Responsabilité</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
