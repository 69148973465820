import React from 'react'
import { Tab } from '@headlessui/react'
import { ExternalLinkIcon } from '@heroicons/react/outline'
import { StaticImage } from "gatsby-plugin-image"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Tabs() {

  return (
    <div className="bg-white">
        <div className="max-w-7xl mx-auto w-full px-2 py-16 sm:px-0">
        <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl bg-blue-400/20 p-1">
                <Tab
                key={1}
                className={({ selected }) =>
                    classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    selected
                        ? 'bg-white shadow'
                        : 'text-gray-800 hover:bg-white/[0.12] hover:text-blue-400'
                    )
                }
                >Propriétaire</Tab>
                <Tab
                key={2}
                className={({ selected }) =>
                    classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    selected
                        ? 'bg-white shadow'
                        : 'text-gray-800 hover:bg-white/[0.12] hover:text-blue-400'
                    )
                }
                >Professions immobilières</Tab>
                <Tab
                key={3}
                className={({ selected }) =>
                    classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    selected
                        ? 'bg-white shadow'
                        : 'text-gray-800 hover:bg-white/[0.12] hover:text-blue-400'
                    )
                }
                >Entreprises</Tab>
            </Tab.List>
            <Tab.Panels className="mt-4">
                <Tab.Panel
                key={1}
                >



<div className="relative bg-gray-700">
    <div className="h-56 bg-blue-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <StaticImage 
                className="w-full h-full object-cover"
                src="../../images/proprietaire.jpg" 
                alt="Proprietaire"
                />
    </div>
    <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
        <p className="text-base font-semibold uppercase tracking-wider text-gray-300">Vous êtes Propriétaire</p>
        <h2 className="mt-2 text-white text-3xl tracking-tight sm:text-4xl">Sécurisez vos projets immobiliers</h2>
        <p className="mt-3 text-lg text-gray-300">
        Actuel propriétaire, futur vendeur, candidat à la propriété d’un logement neuf ou ancien, nos expertises s’adaptent à vos projets. Nous sommes à vos côtés.
        </p>
        <div className="mt-8">
        <div className="inline-flex rounded-md shadow">
            <a
            href="/pour-qui/proprietaire/"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
            >
            En savoir plus
            <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </a>
        </div>
        </div>
    </div>
    </div>
</div>


                </Tab.Panel>  
                <Tab.Panel
                key={2}
                >



<div className="relative bg-gray-700">
    <div className="h-56 bg-blue-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <StaticImage 
                className="w-full h-full object-cover"
                src="../../images/profession-immobilier.jpg" 
                alt="Entreprise"
                />
    </div>
    <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
        <p className="text-base font-semibold uppercase tracking-wider text-gray-300">Professions immobilières</p>
        <h2 className="mt-2 text-white text-3xl tracking-tight sm:text-4xl">Vous sommes aux côtés des professionnels de l’immobiliers</h2>
        <p className="mt-3 text-lg text-gray-300">
        Les aspects techniques du bâtiment et les avantages de l’expertise sont nombreux. Ils ne sont pas réservés aux particuliers. Professionnels de l'immobilier (Agent immobilier, Collectivités territoriales) découvrez comment nous pouvons vous aider.
        </p>
        <div className="mt-8">
        <div className="inline-flex rounded-md shadow">
            <a
            href="/pour-qui/professions-immobilieres/"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
            >
            En savoir plus
            <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </a>
        </div>
        </div>
    </div>
    </div>
</div>


                </Tab.Panel>
                <Tab.Panel
                key={3}
                >




<div className="relative bg-gray-700">
    <div className="h-56 bg-blue-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <StaticImage 
                className="w-full h-full object-cover"
                src="../../images/entreprise.jpg" 
                alt="Entreprise"
                />
    </div>
    <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
        <p className="text-base font-semibold uppercase tracking-wider text-gray-300">Entreprises</p>
        <h2 className="mt-2 text-white text-3xl tracking-tight sm:text-4xl">Des expertises sur mesure</h2>
        <p className="mt-3 text-lg text-gray-300">
        A l'instar d’un investissement réalisé par un particulier, les entreprises ont elles-aussi des raisons de faire appel à un expert en bâtiment. Nous réalisons des missions d’expertise technique globales. Défendez vos intérêts grâce à notre expertise dans tous les aspects immobiliers.<br />
Désordres, négociations de travaux, malfaçon, nous réalisons des missions sur mesure.

        </p>
        <div className="mt-8">
        <div className="inline-flex rounded-md shadow">
            <a
            href="/contact/"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
            >
            En savoir plus
            <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </a>
        </div>
        </div>
    </div>
    </div>
</div>


                </Tab.Panel>      
            
            </Tab.Panels>
        </Tab.Group>
        </div>
    </div>
  )
}
