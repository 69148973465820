import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ChevronRightIcon } from '@heroicons/react/solid'
  
  export default function Posts() {
    return (
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Nos différentes missions d’expertise bâtiment</h2>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
              Vous souhaitez comprendre les causes de désordres (fissure, humidité…) qui touchent votre maison ou votre appartement.<br />
              Vous êtes inquiet et souhaitez évaluer les risques et menaces.<br />
              Vous cherchez des solutions en termes de remèdes, traitement et travaux et attendez également des réponses sur les recours envisageables.<br />
              Dubois Expertise vous propose différentes formules d’accompagnement.<br />
              <a className="mt-3 text-xl text-blue-500 underline" href="/missions/">Découvrez nos missions.</a>
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <StaticImage 
                    className="h-48 w-full object-cover"
                    src="../../images/avis-avant-acquisition.jpg" 
                    alt="Avis avant acquisition"
                    />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <a href="/missions/avis-acquisition/" className="block mt-2">
                      <h3 className="text-xl font-semibold text-gray-900">Avis avant acquisition</h3>
                      <ul className="mt-3 text-base text-gray-500">
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Sécuriser votre projet d’achat immobilier</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Évaluer l’état structurel du bâtiment</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Après un rdv sur site ou avis à distance</li>
                      </ul>
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <StaticImage 
                    className="h-48 w-full object-cover"
                    src="../../images/expertise-fissure-prestation.jpg" 
                    alt="Expertise fissure"
                    />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <a href="/missions/expertise-technique-construction/fissures/" className="block mt-2">
                      <h3 className="text-xl font-semibold text-gray-900">Expertise Fissures</h3>
                      <ul className="mt-3 text-base text-gray-500">
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Comprendre l’origine des fissures</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Identifier les travaux de réparation</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Étudier les recours possibles</li>
                      </ul>
                    </a>
                  </div>
                  
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <StaticImage 
                    className="h-48 w-full object-cover"
                    src="../../images/expertise-humidite.jpg" 
                    alt="Expertise humidité"
                    />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <a href="/missions/expertise-technique-construction/humidite/" className="block mt-2">
                      <h3 className="text-xl font-semibold text-gray-900">Expertise Humidité</h3>
                      <ul className="mt-3 text-base text-gray-500">
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Trouver les causes de l’humidité</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Traiter efficacement les désordres</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Etre conseillé sur le plan des recours</li>
                      </ul>
                    </a>
                  </div>
                  
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <StaticImage 
                    className="h-48 w-full object-cover"
                    src="../../images/expertise-batiment.jpg" 
                    alt="Expertise Bâtiment"
                    />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <a href="/missions/expertise-technique-construction/batiment/" className="block mt-2">
                      <h3 className="text-xl font-semibold text-gray-900">Expertise Bâtiment</h3>
                      <ul className="mt-3 text-base text-gray-500">
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Évaluer l’état technique d’un bâtiment</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Se défendre en cas de litige travaux</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Définir une stratégie assurantielle</li>
                      </ul>
                    </a>
                  </div>
                  
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <StaticImage 
                    className="h-48 w-full object-cover"
                    src="../../images/tele-expertise.jpg" 
                    alt="Télé expertise"
                    />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <a href="/missions/conseil-technique/tele-expertise/" className="block mt-2">
                      <h3 className="text-xl font-semibold text-gray-900">Télé-expertise</h3>
                      <ul className="mt-3 text-base text-gray-500">
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Mission de conseil technique</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Difficulté de se déplacer</li>
                        <li className="py-1"><ChevronRightIcon className="h-6 w-6 text-blue-500 inline-block pb-1" aria-hidden="true" /> Budget plus adapté</li>
                      </ul>
                    </a>
                  </div>
                  
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <StaticImage 
                    className="h-48 w-full object-cover"
                    src="../../images/contact.jpg" 
                    alt="Contact"
                    />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <a href="/contact/contacter-nous/" className="block mt-2">
                      <h3 className="text-xl font-semibold text-gray-900">Contact</h3>
                      <p className="mt-3 text-base text-gray-500">Vous désirez recevoir une devis personnalisé et chiffrer.<br />
                      <a className="mt-3 text-base text-blue-500 underline" href="/contact/devis/">Cliquez ici.</a>
                      </p>
                    </a>
                  </div>
                  
                </div>
              </div>

          </div>
        </div>
      </div>
    )
  }
  