import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

/**
 * 
 * <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
 */

export default function Header() {
  return (
    <div className="relative bg-gray-100 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-gray-100 sm:pb-16 md:pb-20 lg:max-w-7xl lg:w-full lg:pb-28 xl:pb-32">

          <main className="mx-auto max-w-7xl lg:h-80 lg:pt-10">

            <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
                <svg
                    className="z-10 hidden lg:block absolute inset-y-0 h-full w-48 text-gray-100 transform right-0"
                    fill="currentColor"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                    aria-hidden="true"
                >
                    <polygon points="50,0 100,0 100,100 95,100" />
                </svg>
                <StaticImage 
                className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                src="../../images/expertise-fissure.jpg" 
                alt="Expertise Fissure"
                />
            </div>

            <div className="px-4 lg:absolute sm:text-center lg:text-left lg:w-1/2 lg:right-0">
              <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                <span className="block xl:inline">Dubois Expertise</span>{' '}
              </h1>
              <h2 className="text-1xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">
                <span className="block text-blue-600 xl:inline">Votre Expert bâtiment indépendant</span>
              </h2>
              <p className="mt-2 text-base text-gray-500 sm:mt-2 sm:text-lg md:mt-2 md:text-xl lg:mx-0">
              Dubois Expertise a son siège social à Caluire-et-Cuire près de Lyon. Néanmoins, nos équipes techniques vous accompagnent partout en France.
              </p>
              <p className="mt-2 text-base text-gray-500 sm:mt-2 sm:text-lg md:mt-2 md:text-xl lg:mx-0">
              Nous intervenons sur des missions d’expertise (fissuration, humidité, litige de la construction) pour le compte de particuliers, professions immobilières et entreprises.
              </p>
              <p className="mt-2 text-base text-gray-500 sm:mt-2 sm:text-lg md:mt-2 md:text-xl lg:mx-0">
              Vous projetez l’achat d’un bien immobilier. Nous pouvons également vous donner un avis technique avant acquisition. Pour les autres problématiques mineures, une télé-expertise est envisageable.
              </p>
              <div className="mt-2 sm:mt-2 sm:flex sm:justify-center">
                <div className="rounded-md shadow">
                  <a
                    href="/contact/"
                    className="w-full flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-2 md:text-lg md:px-10"
                  >
                    Demandez un devis
                  </a>
                </div>
              </div>
            </div>

          </main>

        </div>
      </div>
    </div>
  )
}
