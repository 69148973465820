import React from "react"

import { Helmet } from "react-helmet"
import Banner from "../blocs/banner"
import Header from "../blocs/header"
import Footer from "../blocs/footer"
import Section1 from "../blocs/home/section1"
import Section2 from "../blocs/home/section2"
import Section3 from "../blocs/home/section3"
import Section4 from "../blocs/home/section4"

export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Dubois-expertise.fr</title>
        <meta name="description" content="Description" />
        <link rel="canonical" href="https://www.dubois-expertise.fr/" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Banner />
        <Header />
      </header>
      <main className="relative">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}